<template>
  <v-layout wrap class="justify-center">
    <v-flex xs12 sm8 md4>
      <v-form v-model="valid" lazy-validation>
        <v-card class="elevation-4 mt-5 mx-2">
          <v-toolbar dark color="darken-2" flat>
            <v-toolbar-title>{{ $t('Reset Password') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <template v-if="!result">
              <v-form ref="passwordForm" v-model="valid">
                <v-text-field v-model="user.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="[rules.required, rules.min]" :type="showPassword ? 'text' : 'password'"
                              name="password" :label="$t('Password')" hint="At least 8 characters"
                              @click:append="showPassword = !showPassword"
                />
                <v-text-field v-model="user.passwordConfirmation" :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="[rules.required, rules.matches]" :type="showPasswordConfirmation ? 'text' : 'password'"
                              name="password-confirmation" :label="$t('Confirm Password')"
                              @click:append="showPasswordConfirmation = !showPasswordConfirmation" block
                />
              </v-form>
            </template>
            <template v-else>
              <div>{{ $t(result.message) }}</div>
            </template>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <template v-if="!result">
              <router-link to="/user/authentication" class="mb-4 mt-1">{{ $t('Back to login') }}</router-link>
              <v-btn color="success" class="mb-4 mt-1 mx-4 white--text" :loading="loading" :disabled="loading"
                     depressed @click.stop="validate"
              >{{ $t('Change password') }}</v-btn>
            </template>
            <template v-else-if="result.status === 'success'">
              <v-btn color="success" class="mb-4 mt-1 mx-4 white--text" depressed @click.stop="$router.push('/sign-in')"
              >{{ $t('Login') }}</v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ResetPassword',
  data () {
    return {
      valid: false,
      user: {
        password: '',
        passwordConfirmation: ''
      },
      rules: {
        required: value => !!value || this.$t('Required'),
        min: v => (v && v.length >= 8) || this.$t('Min 8 characters'),
        matches: value => this.user.password === value || this.$t('Password and confirmation must match')
      },
      showPassword: false,
      showPasswordConfirmation: false,
      loading: false,
      result: null
    }
  },
  methods: {
    ...mapActions({
      resetPassword: 'user/resetPassword'
    }),
    validate () {
      this.loading = true
      if (!this.$refs.passwordForm.validate()) return
      this.resetPassword({
        email: this.$route.params.email,
        token: this.$route.params.token,
        password: this.user.password,
        password_confirmation: this.user.passwordConfirmation
      })
        .then(result => {
          this.result = result
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    if (!this.$route.params.email || !this.$route.params.token) {
      this.$router.push('/sign-in')
    }
  }
}
</script>

